import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ciplGlobalProductDb } from '../../../api/Form';
import { toast } from 'react-toastify';
import { withRouter } from "react-router-dom";

class GlobalProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            categoryId: null,
            categoryName: "",
            categoryRange: "",
            currentProduct: null,
            showDeleteWarning: false,
            deviceADcode: localStorage.getItem('adCodes'),
            userId: localStorage.getItem('userId')
        };
    }

    componentDidMount() {
        const fetchProductList = async (categoryId) => {
            const { deviceADcode, userId } = this.state;
            const getProductsPayload = {
                deviceADcode,
                userId,
                mode: 'edit-global-product-list',
                id: categoryId
            };
            try {
                const response = await ciplGlobalProductDb(getProductsPayload)
                if (response?.data)
                    this.setState({ products: response.data.data[0].items, categoryName: response.data.data[0].categoryName, categoryRange: response.data.data[0].codeRange });
            }
            catch (error) {

            };
        };
        const params = new URLSearchParams(window.location.search);
        const categoryIdFromUrl = params.get("category-id");
        if (categoryIdFromUrl) {
            this.setState({ categoryId: categoryIdFromUrl });
            fetchProductList(categoryIdFromUrl);
        }
    }

    closeDeleteWarning = () => {
        this.setState({ showDeleteWarning: false });
    }

    deleteProduct = async () => {
        const { deviceADcode, userId } = this.state;
        const params = new URLSearchParams(window.location.search);
        const categoryIdFromUrl = params.get("category-id");
        const updatedProductList = this.state.products.map(product => {
            return { ...product, isDeleted: product.name === this.state.currentProduct.name }
        });
        const editProductListPayload = {
            id: categoryIdFromUrl,
            deviceADcode,
            userId,
            mode: 'update-global-product-list',
            categoryName: this.state.categoryName,
            codeRange: this.state.categoryRange,
            items: updatedProductList,
            isGlobalSave: 1
        };

        try {
            const response = await ciplGlobalProductDb(editProductListPayload)
            if (response?.data) {
                const newProductListState = updatedProductList.filter(product => !product.isDeleted);
                this.closeDeleteWarning();
                this.setState({ products: newProductListState });
                toast.success(response.data.message);
                if (newProductListState.length === 0) {
                    this.props.history.push('/admin/global-product-database')
                }
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || "An unexpected error occurred.");
        }
        finally {
            this.setState({ showDeleteWarning: false })
        }
    }

    render() {

        const { location, history } = this.props;
        const basePath = location.pathname.split('/').slice(0, -1).join('/');
        const redirectPathForEditList = `${basePath}/global-create-product-list?category-id=${this.state.categoryId}`;

        return (
            <>
                <Modal isOpen={this.state.showDeleteWarning} toggle={this.closeDeleteWarning}>
                    <ModalHeader>Are you sure?</ModalHeader>
                    <ModalBody>
                        <div className="row d-flex justify-content-center py-2 px-4">
                            {this.state.products.length > 1 ? "This action can not be reversed. This product will be permamemtly deleted. Do you want to delete?"
                                : "Deleting this product will also delete its product list (category). Do you want to delete?"
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.deleteProduct}>Delete</Button>
                        <Button color="secondary" onClick={this.closeDeleteWarning}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <div className='w-100 px-0 py-2'>
                    <Card>
                        <CardHeader onClick={() => history.push(`${basePath}/global-product-database`)} className="c-header">Global Database</CardHeader>
                        <CardBody>
                            <div className="d-flex justify-content-between w-100 my-3">
                                <h4 className="ml-3">{this.state.categoryName}</h4>
                                <div className='ms-auto text-end mr-3'>
                                    <Button style={{ whiteSpace: "nowrap", background: "var(--main-bg-color-container-header)" }} color={"primary"} onClick={() => history.push(redirectPathForEditList)}> Edit Product List</Button>
                                </div>
                            </div>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Product Name 11</th>
                                        <th>HS Code/Tariff No</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.products.map((product) => (
                                        <tr key={`product-${product.id}`}>
                                            <td>{product.name}</td>
                                            <td>{product.code}</td>
                                            <td>
                                                <i onClick={() => {
                                                    this.setState({ showDeleteWarning: true });
                                                    this.setState({ currentProduct: product })
                                                }} class='fa fa-trash icons-remove' style={{ fontSize: "20px", color: "red" }} role='button' id='delete-temp'></i>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </div>
            </>

        );
    }
}

export default withRouter(GlobalProductList);
