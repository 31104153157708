import React, { Component } from "react";
import { Button, Card, CardBody, CardHeader, Row } from "reactstrap";
import { ciplGlobalProductDb } from "../../../api/Form";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";

class GlobalCreateProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            categoryName: "",
            categoryRange: "",
            categoryId: null,
            products: [],
            userIdFromProduct: null,
            isGlobalAccess: false,
            globalAccessPopup: false,
            productToAdd: { name: "", code: "" },
            duplicateProductIndex: null,
            initialValues: null,
            deviceADcode: localStorage.getItem('adCodes'),
            userId: localStorage.getItem('userId')
        };
    }

    componentDidMount() {
        const fetchCategoryData = async (categoryId) => {
            const { deviceADcode, userId } = this.state;
            const getProductsPayload = {
                deviceADcode,
                userId,
                mode: "edit-global-product-list",
                id: categoryId,
            };

            try {
                const response = await ciplGlobalProductDb(getProductsPayload)
                if (response?.data) {
                    this.setState({
                        initialValues: response.data.data[0],
                        categoryName: response.data.data[0].categoryName,
                        products: response.data.data[0].items,
                        userIdFromProduct: response.data.data[0].userId,
                        categoryRange: response.data.data[0].codeRange,
                    });
                }
            }
            catch (error) {
                toast.error(error?.response?.data?.message || "An unexpected error occurred.");
            }
        }
        const params = new URLSearchParams(window.location.search);
        const categoryIdFromUrl = params.get("category-id");
        if (categoryIdFromUrl) {
            this.setState({ editMode: true, categoryId: categoryIdFromUrl });
            fetchCategoryData(categoryIdFromUrl);
        }
    }

    handleSave = async (values, editedProducts) => {
        const { deviceADcode, userId } = this.state;
        const payload = {
            deviceADcode,
            userId: this.state.editMode ? this.state.userIdFromProduct : userId,
            mode: this.state.editMode ? "update-global-product-list" : "create-global-product-list",
            categoryName: values.categoryName,
            codeRange: values.categoryRange,
            items: this.state.editMode ? editedProducts : values.products,
            isGlobalSave: 1,
            id: this.state.editMode ? this.state.categoryId : null,
        };

        let response;
        try {
            response = await ciplGlobalProductDb(payload);

            if (response?.data?.data) {
                toast.success(response.data.message);
                this.setState({ duplicateProductIndex: null })
                const { location, history } = this.props;
                const basePath = location.pathname.split("/").slice(0, -1).join("/");
                setTimeout(() => history.push(`${basePath}/global-product-database`), 1500);
            }
        } catch (err) {
            // Safely access the error message
            const errorMessage = err?.response?.data?.message || "An unexpected error occurred.";
            const duplicateIndex = err?.response?.data?.data?.duplicate;
            if (duplicateIndex)
                this.setState({ duplicateProductIndex: duplicateIndex })
            else this.setState({ duplicateProductIndex: null })
            // Display the error message using toast
            toast.error(errorMessage);
        }

    };

    isDuplicacyInProducts = (products, productToAdd) => {
        const duplicateProduct = products.find(
            (product) => String(product.name) === String(productToAdd.name) && String(product.code) === String(productToAdd.code))
        if (duplicateProduct) {
            toast.error(`Duplicate product found with name ${duplicateProduct.name}`);
            return true;
        }
        return false;
    }

    render() {
        const { editMode, categoryName, products, productToAdd, categoryRange } = this.state;

        // Validation schema
        const validationSchema = Yup.object().shape({
            categoryName: Yup.string().required("Category name is required"),
            categoryRange: Yup.string().required("Category range is required").matches(
                /^(\d{1,2}-\d{1,2})$/,
                'Please enter a valid range'
            )
                .test('valid-range', 'Start number should be less than the end number', (value) => {
                    if (!value) return false; // If empty, Yup will handle this as required
                    const [start, end] = value.split('-').map(Number);
                    return start < end;
                }),
            products: Yup.array()
                .of(
                    Yup.object().shape({
                        name: Yup.string().required("Product name is required"),
                        code: Yup.string()
                            .matches(/^[0-9-]+$/, "Only numbers and hyphens are allowed")
                    })
                ),
            productToAdd: Yup.object().shape({
                code: Yup.string()
                    .matches(/^[0-9-]+$/, "Only numbers and hyphens are allowed in HS Code")
            })
        });
        const validateProducts = (products, categoryRange) => {

            const [start, end] = categoryRange.split("-");

            // Validate each product's first two letters
            for (let product of products) {
                const firstTwoLetters = String(product.code).slice(0, 2);
                if (!firstTwoLetters || firstTwoLetters < start || firstTwoLetters > end) {
                    return false; // Invalid product if first two letters are out of range
                }
            }
            return true;
        };

        return (
            <>
                <div className="w-100 px-0 py-2">
                    <Card>
                        <CardHeader
                            onClick={() =>
                                this.props.history.push(
                                    `${this.props.location.pathname.split("/").slice(0, -1).join("/")}/global-product-database`
                                )
                            }
                            className="c-header"
                        >
                            Global Database
                        </CardHeader>
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <h4 className="mt-3">{editMode ? "Edit" : "Create"} Global Products List</h4>
                                <button
                                    style={{ color: "white", height: "40px", background: "var(--main-bg-color-container-header)" }}
                                    onClick={() => this.props.history.goBack()}
                                    className="rounded px-4"
                                >
                                    Back
                                </button>
                            </div>
                            <Formik
                                enableReinitialize
                                initialValues={{ categoryName, products, productToAdd, categoryRange }}
                                validationSchema={validationSchema}
                                onSubmit={async (values, { setSubmitting }) => {
                                    if (values.products.length === 0) {
                                        toast.error("At least one product is required to create product list");
                                        setSubmitting(false);
                                        return;
                                    }

                                    // Validate products before submitting
                                    if (!validateProducts(values.products, values.categoryRange)) {
                                        toast.error("HS code should have first two characters in the category range.");
                                        setSubmitting(false);
                                        return; // Prevent form submission if validation fails for hs code
                                    }

                                    const updatedProducts = values.products.map((product) => {
                                        // Check if the product was edited or deleted
                                        if (!product.isEdited) {
                                            product.isEdited = false;
                                        }
                                        if (!product.isDeleted) {
                                            product.isDeleted = false;
                                        }
                                        if (!product.id) {
                                            product.id = null;
                                        }
                                        product.categoryId = this.state.categoryId;
                                        return product;
                                    });

                                    if (editMode) {
                                        await this.handleSave(values, updatedProducts);
                                    }
                                    else {
                                        await this.handleSave(values);
                                    }
                                    setSubmitting(false);
                                }}
                            >
                                {({ values, errors, touched, validateForm, setFieldValue, handleSubmit }) => (
                                    <Form>
                                        <Row>
                                            <div className="mb-3 col-md-6">
                                                <label>Category Name</label>
                                                <Field
                                                    type="text"
                                                    name="categoryName"
                                                    className={`form-control ${errors.categoryName && touched.categoryName ? "is-invalid" : ""
                                                        }`}
                                                    placeholder="Category name"
                                                />
                                                {errors.categoryName && touched.categoryName && (
                                                    <div className="invalid-feedback">{errors.categoryName}</div>
                                                )}
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label>Category Range</label>
                                                <Field
                                                    type="text"
                                                    name="categoryRange"
                                                    className={`form-control ${errors.categoryRange && touched.categoryRange ? "is-invalid" : ""
                                                        }`}
                                                    placeholder="Category range"
                                                />
                                                {errors.categoryRange && touched.categoryRange && (
                                                    <div className="invalid-feedback">{errors.categoryRange}</div>
                                                )}
                                            </div>
                                        </Row>

                                        <FieldArray name="products">
                                            {({ remove, push }) => (
                                                <>
                                                    {values.products.map((product, index) => {
                                                        if (product.isDeleted)
                                                            return null;
                                                        return <div
                                                            className="row mb-3 d-flex align-items-top"
                                                            key={`product-${product}`}
                                                        >
                                                            <div className="col-md-6">
                                                                <label>Item Name/Description</label>
                                                                <Field
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value;
                                                                        setFieldValue(`products.${index}.name`, newValue);
                                                                        if (newValue !== this.state.initialValues.items[index]?.name) {
                                                                            setFieldValue(`products.${index}.isEdited`, true);
                                                                        }
                                                                    }}
                                                                    maxLength="100"
                                                                    name={`products.${index}.name`}
                                                                    className={`form-control ${(errors.products &&
                                                                        errors.products[index] &&
                                                                        errors.products[index].name &&
                                                                        touched.products &&
                                                                        touched.products[index] &&
                                                                        touched.products[index].name) || index === this.state.duplicateProductIndex
                                                                        ? "is-invalid"
                                                                        : ""
                                                                        }`}
                                                                    placeholder="Product Name"
                                                                />
                                                                {errors.products &&
                                                                    errors.products[index] &&
                                                                    errors.products[index].name &&
                                                                    touched.products &&
                                                                    touched.products[index] &&
                                                                    touched.products[index].name && (
                                                                        <div className="invalid-feedback">
                                                                            {errors.products[index].name}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label>HS Code/ Tariff No</label>
                                                                <Field
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value;
                                                                        setFieldValue(`products.${index}.code`, newValue);
                                                                        if (newValue !== this.state.initialValues?.products?.[index]?.code) {
                                                                            setFieldValue(`products.${index}.isEdited`, true);
                                                                        }
                                                                    }}
                                                                    maxLength="15"
                                                                    name={`products.${index}.code`}
                                                                    className={`form-control ${(errors.products &&
                                                                        errors.products[index] &&
                                                                        errors.products[index].code &&
                                                                        touched.products &&
                                                                        touched.products[index] &&
                                                                        touched.products[index].code) || index === this.state.duplicateProductIndex
                                                                        ? "is-invalid"
                                                                        : ""
                                                                        }`}
                                                                    placeholder="HS Code"
                                                                />
                                                                {errors.products &&
                                                                    errors.products[index] &&
                                                                    errors.products[index].code &&
                                                                    touched.products &&
                                                                    touched.products[index] &&
                                                                    touched.products[index].code && (
                                                                        <div className="invalid-feedback">
                                                                            {errors.products[index].code}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                            <button
                                                                style={{ height: "40px", marginTop: "30px", width: "110px" }}
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={() => {
                                                                    if (values.products.length === 1) {
                                                                        toast.error("At least one product is required");
                                                                        return;
                                                                    }
                                                                    setFieldValue(`products.${index}.isDeleted`, true);
                                                                }}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div>
                                                    })}
                                                    <div className="row mb-3 d-flex align-items-top">
                                                        <div className="col-md-6">
                                                            <label>Item Name/Description</label>
                                                            <Field
                                                                maxLength="100"
                                                                name={`productToAdd.name`}
                                                                className={`form-control ${errors.products &&
                                                                    errors.productToAdd &&
                                                                    errors.productToAdd.name &&
                                                                    touched.products &&
                                                                    touched.productToAdd &&
                                                                    touched.productToAdd.name
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                                placeholder="Product Name"
                                                            />
                                                            {
                                                                errors.productToAdd?.name &&
                                                                touched.productToAdd?.name && (
                                                                    <div className="invalid-feedback">
                                                                        {errors.productToAdd.name}
                                                                    </div>
                                                                )}
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>HS Code/ Tariff No</label>
                                                            <Field
                                                                maxLength="15"
                                                                name={`productToAdd.code`}
                                                                className="form-control"
                                                                placeholder="HS Code"
                                                            />
                                                            {
                                                                errors.productToAdd?.code &&
                                                                touched.productToAdd?.code && (
                                                                    <div className="invalid-feedback">
                                                                        {errors.productToAdd?.code}
                                                                    </div>
                                                                )}
                                                        </div>
                                                        <button
                                                            style={{ height: "40px", marginTop: "30px", width: "110px", background: "var(--main-bg-color-container-header)" }}
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={async () => {
                                                                const duplicacyFoundInProducts = this.isDuplicacyInProducts(values.products, values.productToAdd);
                                                                if (duplicacyFoundInProducts)
                                                                    return
                                                                const errors = await validateForm();
                                                                const hscodeErr = errors?.productToAdd?.code;
                                                                if (hscodeErr) {
                                                                    toast.error(hscodeErr)
                                                                    return;
                                                                }
                                                                const { productToAdd, categoryRange } = values;
                                                                if (productToAdd.name.trim() === "") {
                                                                    toast.error("Cannot add product with empty description");
                                                                    return;
                                                                }
                                                                if (!validateProducts([productToAdd], categoryRange)) {
                                                                    toast.error("HS code should have first two characters in the category range.");
                                                                    return;
                                                                }
                                                                push({ name: productToAdd.name, code: productToAdd.code });
                                                                setFieldValue("productToAdd", { name: "", code: "" })
                                                            }}
                                                        >
                                                            + Add Item
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </FieldArray>

                                        <div className="d-flex justify-content-end w-100 my-3">
                                            <Button
                                                style={{ background: "var(--main-bg-color-container-header)" }}
                                                color="primary"
                                                type="submit"
                                            >
                                                Save Global
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </div>
            </>
        );
    }
}

export default withRouter(GlobalCreateProductList);