import React, { Component } from "react";
import { Button, Table } from "reactstrap";
import DOMPurify from 'dompurify';
import currencies from '../../Invoice/currencies.json';

const discountTypes = { absolute: "Absolute value", percentage: "Percentage" };
class PrintCiplPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    isEmptyContent(htmlContent) {
        // Remove all HTML tags
        const strippedContent = htmlContent
            .replace(/<\/?[^>]+(>|$)/g, "") // Removes HTML tags
            .replace(/&nbsp;/g, " ")        // Converts &nbsp; to space
            .trim();                        // Trims leading/trailing whitespace

        // Return true if content is empty or only whitespace
        return strippedContent === "";
    }

    render() {
        const ciplPrintData = JSON.parse(localStorage.getItem("cipl_print"));
        const website = ciplPrintData.headerData?.website;
        const tagline = ciplPrintData.headerData?.tagLine;
        const currency = ciplPrintData?.currency;
        const symbol = currencies.find((currencyItem) => currencyItem.iso_code === currency)?.symbol;
        const productData = ciplPrintData?.products || JSON.parse(ciplPrintData?.productDetails);
        const calculateTotalWeight = (productData) => {
            return productData.reduce((total, product) => total + parseFloat(product.weight || 0), 0);
        };

        const calculateTotalPrice = (productData, field) => {
            return productData.reduce((total, product) => total + parseFloat(product[field] || 0), 0);
        };
        const ciplTypes = { packingList: "Packing List", invoice: "Invoice" };
        const ciplNo = ciplPrintData.ciplType === ciplTypes.invoice ? (ciplPrintData.invoiceNo || ciplPrintData.invoiceNumber) :
            (ciplPrintData.packingListNo || ciplPrintData.packingListNumber);
        const sanitizedCommentData = DOMPurify.sanitize(ciplPrintData.comments);
        const discountType = (ciplPrintData.discountType?.value || ciplPrintData.discountType);

        return (
            <div className="container bg-white p-4" style={{ fontFamily: "Arial, sans-serif", marginTop: "20px" }}>
                {/* <div style={{ pageBreakAfter: "always" }}> */}
                {/* Header */}
                <div className="row mb-4">
                    <div className="col-12 col-md-8">
                        <h3 className="text-dark font-weight-bold">{ciplPrintData.headerData?.title || ""}</h3>
                        <p className="text-muted small">
                            {ciplPrintData.headerData?.address || ""}
                            <br />
                            <span> <i className="fa fa-phone-square"></i>Tel: {ciplPrintData.headerData?.phoneNumber || ""}</span>
                            <br />
                            <a href={`mailto:${ciplPrintData.headerData?.email}`} className="text-primary">
                                <i className="fa fa-envelope"></i> Email: {ciplPrintData.headerData?.email || ""}
                            </a>
                            <br />
                            {website && <a href={website} className="text-primary">
                                <i className="fa fa-phone-square"></i> Website: {website}
                            </a>
                            }
                        </p>
                        {tagline && <p className="text-muted font-italic small">{tagline}.</p>}
                    </div>
                    <div className="col-12 col-md-4 text-md-right">
                        <h4 style={{ opacity: "0.25" }} className="font-weight-bold">{this.props.ciplType}</h4>
                        <p className="small d-flex flex-wrap">
                            <span className="col-6 col-md-6 mb-2">
                                <strong>Date:</strong> {ciplPrintData.date || ""}
                            </span>
                            <span className="col-6 col-md-6 mb-2">
                                <strong>Seal No:</strong> {ciplPrintData.sealNo || ciplPrintData.sealNumber}
                            </span>
                            <span className="col-6 col-md-6 mb-2">
                                <strong>Container No:</strong> {ciplPrintData.containerNumber || ciplPrintData.containerNo}
                            </span>
                            <span className="col-6 col-md-6 mb-2">
                                <strong>MOT:</strong> {ciplPrintData.mot || ciplPrintData.modeOfTransport}
                            </span>
                            <span className="col-6 col-md-6 mb-2">
                                <strong>Bill No:</strong> {ciplPrintData.billNo || ciplPrintData.billNumber}
                            </span>
                            <span className="col-6 col-md-6 mb-2">
                                <strong>Customer No:</strong> {ciplPrintData.customerNumber || ciplPrintData.customerNo}
                            </span>
                        </p>
                    </div>
                </div>


                {/* Consignee & Ship To */}
                <div className="row mb-4">
                    <div className="col-12 col-md-6 bg-light p-3 rounded">
                        <h4 className="text-danger font-weight-bold">CONSIGNEE:</h4>
                        <p>{ciplPrintData.consignee || ""}
                            <br />
                            {ciplPrintData.consigneeAddress || ""}
                        </p>
                    </div>
                    <div className="col-12 col-md-6 bg-light p-3 rounded">
                        <h4 className="text-danger font-weight-bold">SHIP TO:</h4>
                        <p>{ciplPrintData.shipTo || ""}
                            <br />
                            {ciplPrintData.shipToAddress || ""}
                        </p>
                    </div>
                </div>

                {/* Invoice Details */}
                <Table bordered className="mb-4">
                    <thead>
                        <tr className="bg-light">
                            <th className="text-center">HBL/REF</th>
                            <th className="text-center">{this.props.ciplType?.toUpperCase()} No</th>
                            <th className="text-center">TOTAL WEIGHT</th>
                            <th className="text-center">INCOTERM</th>
                            <th className="text-center">INVOICE TERMS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">{ciplPrintData.hblRef || ""}</td>
                            <td className="text-center">{ciplNo}</td>
                            <td className="text-center">{ciplPrintData.totalWeight || ""}</td>
                            <td className="text-center">{ciplPrintData.incoterm || ""}</td>
                            <td className="text-center">{ciplPrintData.invoiceTerms || ""}</td>
                        </tr>
                    </tbody>
                </Table>

                {/* Product Table */}
                <Table bordered className="mb-4">
                    <thead style={{ backgroundColor: "var(--main-bg-color-container-header)" }} className="text-white">
                        <tr>
                            <th className="align-middle">#</th>
                            <th className="align-middle">Product Description</th>
                            <th className="align-middle">HS Code</th>
                            <th className="align-middle">Weight (Kg)</th>
                            <th className="align-middle">Price ({currency})</th>
                            <th className="align-middle">Dis</th>
                            <th className="align-middle">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productData.map((product, index) => (
                            <tr key={index}>
                                <td className="text-center">{index + 1}</td>
                                <td style={{ maxWidth: "500px" }} className="text-left text-break">{product.name}</td>
                                <td className="text-left">{product.code}</td>
                                <td className="text-center">{product.weight}</td>
                                <td className="text-center">{symbol}{product.price}</td>
                                <td className="text-center">{product.discount === "" || isNaN(product.discount) ? "N/A" : `${discountType === discountTypes.absolute && !isNaN(product.discount) ? symbol : ""}${product.discount}${discountType === discountTypes.percentage && !isNaN(product.discount) ? "%" : ""}`}</td>
                                <td className="text-center">{symbol}{product.finalPrice}</td>
                            </tr>
                        ))}
                        <tr className="font-weight-bold">
                            <td colSpan="3" className="text-right">Total</td>
                            <td className="text-center">{calculateTotalWeight(productData)}</td>
                            <td className="text-center">{symbol}{calculateTotalPrice(productData, "price")}</td>
                            <td></td>
                            <td className="text-center">{symbol}{calculateTotalPrice(productData, "finalPrice")}</td>
                        </tr>
                    </tbody>
                </Table>

                {/* Comments Section */}
                {!this.isEmptyContent(ciplPrintData.comments) && (
                    <div className="bg-light p-3 rounded">
                        <h4 className="text-primary font-weight-bold">Comments</h4>
                        <div dangerouslySetInnerHTML={{ __html: sanitizedCommentData }} />
                    </div>
                )}

                {/* Footer */}
                <div className="text-center mt-5">
                    <h4 className="text-secondary">Thank You For Your Business!</h4>
                </div>

            </div>

            // </div>

        );
    }
}

export default PrintCiplPreview;
