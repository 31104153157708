import React, { Component } from "react";
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ciplGlobalProductDb } from "../../../api/Form";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

class GlobalCategoryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            currentCategory: null,
            showDeleteWarning: false,
            userData: null,
            deviceADcode: localStorage.getItem('adCodes'),
            userId: localStorage.getItem('userId')
        };
    }

    componentDidMount() {
        this.fetchCategories();
    }

    fetchCategories = async () => {
        const { deviceADcode, userId } = this.state;
        const getCategoriesPayload = { deviceADcode, userId, mode: 'get-global-product-list' };

        try {
            const response = await ciplGlobalProductDb(getCategoriesPayload)
            if (response?.data)
                this.setState({ categories: response.data.data });
        }
        catch (error) {
            this.setState({ categories: [] });
        };
    };

    closeDeleteWarning = () => {
        this.setState({ showDeleteWarning: false });
    }
    deleteCategory = async () => {
        const { deviceADcode, userId, currentCategory } = this.state;
        const deleteCategoryPayload = {
            mode: "delete-global-product-list",
            id: currentCategory.id,
            deviceADcode,
            userId
        };
        try {
            const response = await ciplGlobalProductDb(deleteCategoryPayload);
            if (response?.data) {
                this.fetchCategories(); // Refresh category list
                toast.success('Product list deleted successfully!');
            }
        }
        catch (error) {
            toast.error(error.message);
        }
        finally {
            this.closeDeleteWarning();
        };
    }

    render() {

        const { location, history } = this.props;

        const basePath = location.pathname.split('/').slice(0, -1).join('/');

        const redirectPathForProduct = `${basePath}/global-product-list?category-id=`;
        const redirectPathForAddList = `${basePath}/global-create-product-list`;
        const redirectPathForEditList = `${basePath}/global-create-product-list?category-id=`;

        return (

            <>
                <Modal isOpen={this.state.showDeleteWarning} toggle={this.closeDeleteWarning}>
                    <ModalHeader>Are you sure?</ModalHeader>
                    <ModalBody>
                        <div className="row d-flex justify-content-center py-2 px-4">
                            This action can not be reversed. This category will be permamemtly deleted. Do you want to delete?
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.deleteCategory}>Delete</Button>
                        <Button color="secondary" onClick={this.closeDeleteWarning}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <div className='w-100 px-0 py-2'>
                    <Card>
                        <CardHeader className='c-header'>Global Database</CardHeader>
                        <CardBody>
                            <div className="d-flex justify-content-between w-100 my-3 flex-column flex-sm-row">
                                <h4 className="ml-3 text-nowrap">Product List</h4>
                                <div className="d-flex justify-content-end w-100">
                                    <Button
                                        style={{
                                            whiteSpace: "nowrap",
                                            background: "var(--main-bg-color-container-header)",
                                            height: "40px"
                                        }}
                                        color="primary"
                                        onClick={() => history.push(redirectPathForAddList)}
                                    >
                                        + Add Products List
                                    </Button>
                                </div>
                            </div>

                            {this.state.categories.length > 0 ?
                                <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>Category List</th>
                                            <th>HS code Range</th>
                                            <th>Total Items</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.categories.map((category) => (
                                            <tr key={`category-${category.id}`}>
                                                <td>{category.categoryName}</td>
                                                <td>{category.codeRange}</td>
                                                <td>{category.itemCount}</td>
                                                <td>

                                                    <i onClick={() => history.push(`${redirectPathForProduct}${category.id}`)} class='fa fa-eye icons-remove mr-2' style={{ fontSize: "20px", color: "blue" }} role='button'></i>

                                                    <i onClick={() => history.push(`${redirectPathForEditList}${category.id}`)} class='fa fa-edit icons-remove mr-2' style={{ fontSize: "20px", color: "blue" }} role='button'></i>

                                                    <i onClick={() => {
                                                        this.setState({ showDeleteWarning: true, currentCategory: category })
                                                    }} class='fa fa-trash icons-remove' style={{ fontSize: "20px", color: "red" }} role='button' id='delete-temp'></i>


                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                                :
                                <p className="ml-2">No data available</p>
                            }
                        </CardBody>
                    </Card>
                </div>
            </>
        );
    }
}

export default withRouter(GlobalCategoryList);
