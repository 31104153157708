import React, { Component } from "react";
import { Card, CardBody, CardHeader, NavLink, Popover, PopoverBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from "reactstrap";
import { checkScreenAccess, ciplAssignRef, ciplBill, ciplSendEmail, requestAccess } from "../../../api/Form";
import { toast } from "react-toastify";
import { Link, withRouter } from 'react-router-dom';
import Print from "./PrintCipl";

class CIPLList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ciplList: [],
            popoverOpen: false,
            selectedRefId: null, // Stores the ID of the clicked element
            selectedRefNumber: null, // Stores the reference number
            access: false,
            ciplDetail: null,
            print: false,
            showDeleteWarning: false,
            currentCipl: null,
            email: "",
            showEmailPopup: false,
            showAssignRefPopup: false,
            enteredRefNumber: ""
        };
    }

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('auth'));
        if (userInfo && userInfo.accounttypeUsers !== "Admin") {
            const screenAccessPayload = {
                userId: userInfo.id,
                screenType: "Create CIPL"
            }

            checkScreenAccess(screenAccessPayload).then((response) => {
                this.setState({ access: true });
                this.fetchCiplList();
            }).catch((error) => { this.setState({ access: false }); })

        }
        else {
            this.setState({ access: true });
            this.fetchCiplList();
        }
    }

    fetchCiplList = async () => {
        const deviceADcode = localStorage.getItem('adCodes');
        const userId = localStorage.getItem('userId');
        const fetchCiplPayload = { deviceADcode, userId, mode: 'get-cipl-bill-lists' };
        try {
            const response = await ciplBill(fetchCiplPayload);
            if (response?.status) {
                this.setState({
                    ciplList: response.data.data,
                });
            }

        }
        catch (error) {
            this.setState({
                ciplList: [],
            });
        };
    };

    sendRequestAccess = async () => {
        const info = localStorage.getItem('auth');
        const sendInfo = JSON.parse(info);
        requestAccess({
            'username': sendInfo.username,
            'firstName': sendInfo.firstName,
            'lastName': sendInfo.lastName,
            'accounttypeUsers': sendInfo.accounttypeUsers,
            'businessname': sendInfo.businessname,
            'ADCode': sendInfo.ADCode,
            'address': sendInfo.address,
            'email': sendInfo.email,
            'phoneNumber': sendInfo.phonenumber,
            'userId': sendInfo.id,
            'screenType': 'Create CIPL',
        }).then(Response => {
            toast.success(Response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch(err => {
            let message = err.message;
            if (err.response && err.response.data.message) {
                message = err.response.data.message;
            }
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    };

    togglePopover = (refId = null, refNumber = null) => {

        this.setState((prevState) => ({
            popoverOpen: prevState.selectedRefNumber === refNumber ? !prevState.popoverOpen : true,
            selectedRefId: refId,
            selectedRefNumber: refNumber,
        }));
    };

    getCiplDetailsToPrint = (ciplId) => {
        const deviceADcode = localStorage.getItem('adCodes');
        const userId = localStorage.getItem('userId');
        const payload = { id: ciplId, "mode": "edit-cipl-bill", userId, deviceADcode }
        ciplBill(payload).then((response) => {
            this.setState({ ciplDetail: response.data.data })
            localStorage.setItem("cipl_print", JSON.stringify(response.data.data));
        }).catch((err) => {
            toast.error(err)
        })
    }

    handlePrint = (ciplId) => {
        this.getCiplDetailsToPrint(ciplId);
        this.setState({ print: true });
    }

    handleEditAndView = (ciplId, view) => {
        const { location, history } = this.props;
        const basePath = location.pathname.split('/').slice(0, -1).join('/');
        const redirectPath = view ? `${basePath}/create-cipl?cipl-id=${ciplId}&view=${view}` : `${basePath}/create-cipl?cipl-id=${ciplId}`;
        history.push(redirectPath)
    }

    handleDelete = async (ciplId) => {
        const deviceADcode = localStorage.getItem('adCodes');
        const userId = localStorage.getItem('userId');
        const payload = { id: ciplId, userId, deviceADcode, mode: "delete-cipl-bill" }
        try {
            const response = await ciplBill(payload);
            if (response?.data) {
                this.fetchCiplList();
                this.closeDeleteWarning();
                this.setState({ popoverOpen: false, selectedRefId: null, selectedRefNumber: null });
                toast.success("CIPL deleted successfully");
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || "An unexpected error occurred.");
            this.closeDeleteWarning();
        }
    }

    closeDeleteWarning = () => {
        this.setState({ showDeleteWarning: false });
    }

    handleUse = (ciplId) => {
        const { location, history } = this.props;
        const basePath = location.pathname.split('/').slice(0, -1).join('/');
        const redirectPath = `${basePath}/create-cipl?cipl-id=${ciplId}&use-cipl=${true}`;
        history.push(redirectPath)
    }

    validateEmail = () => {
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.state.email || reg.test(this.state.email) === false) {
            toast.error("Please enter a valid email");
            return false;
        }
        return true;
    }

    sendEmail = () => {
        const ciplTypes = { packingList: "Packing List", invoice: "Invoice" };
        const deviceADcode = localStorage.getItem('adCodes');
        const userId = localStorage.getItem('userId');
        const payload = { id: this.state.currentCipl.id, userId, deviceADcode, sendTo: this.state.email, isDownload: false, "isPackingList": this.state.currentCipl.ciplType === ciplTypes.packingList }
        ciplSendEmail(payload).then((response) => {
            toast.success("Email sent successfully")
            this.setState({ popoverOpen: false, selectedRefId: null, selectedRefNumber: null, showEmailPopup: false });
        }).catch((err) => {
            toast.error(err);
            this.setState({ showEmailPopup: false });
        })
    }

    affixToRef = async () => {
        const deviceADcode = localStorage.getItem('adCodes');
        const userId = localStorage.getItem('userId');
        const payload = { id: this.state.currentCipl.id, userId, deviceADcode, refNo: this.state.enteredRefNumber }
        try {
            const response = await ciplAssignRef(payload);
            if (response?.data) {
                toast.success("Assigned to ref successfully");
            }
        } catch (error) {
            toast.error(error?.response?.data?.message?.refNo || "An unexpected error occurred.");
        } finally {
            this.setState({
                popoverOpen: false,
                selectedRefId: null,
                selectedRefNumber: null,
                showAssignRefPopup: false,
            });
        }
    }

    render() {
        const ciplTypes = { packingList: "Packing List", invoice: "Invoice" };
        const { location, history } = this.props;
        const basePath = location.pathname.split('/').slice(0, -1).join('/');
        const { ciplList, popoverOpen, selectedRefId, selectedRefNumber, access, print } = this.state;

        if (!access) {
            return <div className="justify-content-center pt-2 main-contain-title">
                <p>This feature is restricted.</p>
                <Button onClick={() => this.sendRequestAccess()} >
                    Request Access for free
                </Button>
            </div>
        }

        if (print) {
            return <Print ciplType={this.state.ciplDetail?.ciplType} size="150*100mm" goBack={() => this.setState({ print: false, popoverOpen: false, selectedRefId: null, selectedRefNumber: null })} />
        }

        return (
            <>
                <Modal isOpen={this.state.showAssignRefPopup} >
                    <ModalHeader>Assign to ref</ModalHeader>
                    <ModalBody>
                        <div className="col-12">
                            <FormGroup>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        name="enteredRef"
                                        onChange={(e) => this.setState({ enteredRefNumber: e.target.value })}
                                        placeholder="Enter ref number"
                                    />
                                </div>
                            </FormGroup>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={this.affixToRef}
                        >
                            Affix
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => this.setState({ showAssignRefPopup: false })}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.showEmailPopup} toggle={this.closeEmailPopup}>
                    <ModalHeader>Send Email</ModalHeader>
                    <ModalBody>
                        <div className="col-12">
                            <FormGroup>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        name="refno"
                                        onChange={(e) => this.setState({ email: e.target.value })}
                                        placeholder="Enter Email"
                                    />
                                </div>
                            </FormGroup>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="danger"
                            onClick={() => {
                                if (this.validateEmail()) {
                                    this.sendEmail();
                                }
                            }}
                        >
                            Send
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => this.setState({ showEmailPopup: false })}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.showDeleteWarning} toggle={this.closeDeleteWarning}>
                    <ModalHeader>Are you sure?</ModalHeader>
                    <ModalBody className="text-center">
                        This action cannot be reversed. This CIPL will be permanently deleted. Do you want
                        to delete?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => this.handleDelete(this.state.currentCipl.id)}>
                            Delete
                        </Button>
                        <Button color="secondary" onClick={this.closeDeleteWarning}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                <div className="w-100 px-0 py-2">
                    <Card>
                        <CardHeader className="c-header">CIPL List</CardHeader>
                        <CardBody>
                            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
                                <h3 className="text-primary text-center text-md-left">CIPL List</h3>
                                <Button
                                    onClick={() => history.push(`${basePath}/create-cipl`)}
                                    className="btn btn-primary mt-2 mt-md-0"
                                >
                                    + Create new CIPL
                                </Button>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-bordered table-hover">
                                    <thead className="bg-light">
                                        <tr style={{ fontSize: "14px" }}>
                                            <th>REF. #</th>
                                            <th style={{ minWidth: "120px" }}>Date</th>
                                            <th style={{ minWidth: "120px" }}>Container #</th>
                                            <th style={{ minWidth: "120px" }}>Bill #</th>
                                            <th style={{ minWidth: "120px" }}>Seal #</th>
                                            <th>MOT</th>
                                            <th>Customer #</th>
                                            <th style={{ minWidth: "140px" }}>Consignee</th>
                                            <th style={{ minWidth: "140px" }}>Ship To</th>
                                            <th>INVOICE #</th>
                                            <th>Packing List #</th>
                                            <th>Total Weight</th>
                                        </tr>
                                    </thead>
                                    {ciplList.length === 0 && (
                                        <p className="ml-2 mt-2" style={{ width: "300px" }}>
                                            No record found
                                        </p>
                                    )}
                                    <tbody>
                                        {ciplList.map((cipl, index) => {
                                            const refId = `ref-${cipl.id}`;
                                            return (
                                                <tr key={`cipl-${cipl.id}`}>
                                                    <td>
                                                        <NavLink
                                                            href="#"
                                                            className="text-primary"
                                                            id={refId}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.togglePopover(refId, cipl.hblRef);
                                                            }}
                                                        >
                                                            {cipl.hblRef}
                                                        </NavLink>
                                                        <Popover
                                                            placement="bottom"
                                                            isOpen={popoverOpen && selectedRefId === refId}
                                                            target={refId}
                                                            toggle={() => this.togglePopover()}
                                                        >
                                                            <PopoverBody>
                                                                <div className="d-flex flex-column align-items-start">
                                                                    <button
                                                                        onClick={() => this.handleEditAndView(cipl.id, true)}
                                                                        className="btn btn-link p-0 text-decoration-none"
                                                                    >
                                                                        <i className="fa fa-eye icons-remove mr-2"></i>View
                                                                    </button>
                                                                    <button
                                                                        onClick={() => this.handleUse(cipl.id)}
                                                                        className="btn btn-link p-0 text-decoration-none"
                                                                    >
                                                                        <i className="fa fa-eye icons-remove mr-2"></i>Use
                                                                    </button>
                                                                    <button
                                                                        onClick={() => this.handleEditAndView(cipl.id, false)}
                                                                        className="btn btn-link p-0 text-decoration-none"
                                                                    >
                                                                        <i className="fa fa-edit icons-remove mr-2"></i>Edit
                                                                    </button>
                                                                    <button
                                                                        onClick={() =>
                                                                            this.setState({ currentCipl: cipl, showEmailPopup: true })
                                                                        }
                                                                        className="btn p-0 text-primary text-decoration-none"
                                                                    >
                                                                        <i className="fa fa-envelope-o icons-remove mr-2"></i>Send
                                                                        Email
                                                                    </button>
                                                                    <button onClick={() =>
                                                                        this.setState({ currentCipl: cipl, showAssignRefPopup: true })
                                                                    } className="btn btn-link p-0 text-decoration-none">
                                                                        <i className="fa fa-eye icons-remove mr-2"></i>Affix to REF
                                                                    </button>
                                                                    <button
                                                                        onClick={() => this.handlePrint(cipl.id)}
                                                                        className="btn btn-link p-0 text-decoration-none"
                                                                    >
                                                                        <i className="fa fa-print icons-remove mr-2"></i>Print
                                                                    </button>
                                                                    <button
                                                                        onClick={() => {
                                                                            this.setState({ currentCipl: cipl, showDeleteWarning: true });
                                                                        }}
                                                                        className="btn btn-link p-0 text-danger text-decoration-none"
                                                                    >
                                                                        <i className="fa fa-trash icons-remove mr-2"></i>Delete
                                                                    </button>
                                                                </div>
                                                            </PopoverBody>
                                                        </Popover>
                                                    </td>
                                                    <td>{cipl.date}</td>
                                                    <td>{cipl.containerNo}</td>
                                                    <td>{cipl.billNo}</td>
                                                    <td>{cipl.sealNo}</td>
                                                    <td>{cipl.modeOfTransport}</td>
                                                    <td>{cipl.customerNo}</td>
                                                    <td>{cipl.consignee}<br />{cipl.consigneeAddress || ""}</td>
                                                    <td>{cipl.shipTo}<br />{cipl.shipToAddress || ""}</td>
                                                    <td>
                                                        {cipl.ciplType === ciplTypes.invoice ? cipl.invoiceNo : ""}
                                                    </td>
                                                    <td>
                                                        {cipl.ciplType === ciplTypes.packingList ? cipl.packingListNo : ""}
                                                    </td>
                                                    <td>{cipl.totalWeight}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </div >
            </>
        );
    }
}

export default withRouter(CIPLList);
